
var panel = $('#mobile-nav-content');
$('.layout-header .menu-action-menu').clone().prependTo(panel);
$('.layout-navigation .menu-main-menu').clone().prependTo(panel);
$('.layout-header .menu-social-links').clone().appendTo(panel);

$('#mobile-nav').mmenu(
    {
        position: 'right'
    });

$('li').addClass('dontsplit');//used to stop li dividing in columnizer

var domObj = [".layout-content-alt .columnize",".produce-list"]; 
function evaluateBreakpoint(viewport) {
    if (viewport.is('>=sm')) 
    {
        $.each(domObj, function(index,value) 
            { 
                var columinizeContent = $(value);
                if (columinizeContent.data('columnized') == null || columinizeContent.data('columnized') === false) 
                {
                    columinizeContent.columnize({ columns: 2 });
                }
            });
    } 
}

(function ($, viewport) {
    $(document).ready(function () {
        evaluateBreakpoint(viewport);
    });
})(jQuery, ResponsiveBootstrapToolkit);

$('[data-click-target]').click(function (event) {
    var selector = $(this).data('click-target');
    var selected = $(this).find(selector);

    if (selected.length > 0) {
        event.preventDefault();
        window.location = $(selected[0]).attr('href');
    }
});


 $('.detail-blog-post .layout-content').addClass('layout-content-alt');

$('.blog-posts').on("click","li",function(){

    if($(this).find("a").length)
    {
        window.location.href = $(this).find("a:last").attr("href");   
    }
});


function bindEqualColumns() {

    $('.content-header-second,.map-canvas-display').attr('data-column-group', 'map');

    var groupNames = [];

    $('[data-column-group]').each(function () {
        var groupName = $(this).data('columnGroup');

        if (groupNames.indexOf(groupName) == -1) {
            groupNames.push(groupName);
        }
    });

    for (var i = 0; i < groupNames.length; i++) {
        $('[data-column-group=' + groupNames[i] + ']').equalHeightColumns({
            minWidth: (groupNames[i].indexOf('-all'))? 0 : 768,
            checkHeight: 'innerHeight'
        });
    }

    $('.list-tiled').each(function () {
        $(this).children().equalHeightColumns({
            checkHeight: 'innerHeight'
        });
    });

}


function bindEqualColumnGroup(columnGroupName) {
    
    $('[data-column-group=' + columnGroupName + ']').equalHeightColumns({
        minWidth: 768,
        checkHeight: 'innerHeight'
    });
}

bindEqualColumns();
